<template>
  <div class="table-container">
  <table class="custom-table">
    <thead>
      <tr>
        <th class="empty_th"></th>
        <th class="" style="width: 30%;">Название</th>
        <th class="" style="width: 35%">Тренеры</th>
        <th class="" style="width: 35%">Клубы</th>
      </tr>
    </thead>
<!--    <tbody>-->
      <ItemTableGroup
        v-for="(group, i) in this.data_essence"
        :key="i"
        :type="type"
        :item_group="group"
        @deleteGroup="deleteGroup"
        @editGroup="editGroup"
      />
<!--    </tbody>-->
  </table>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import { actionTypesEntered } from "@/store/modules/enteredData";

import ItemTableGroup from "./ItemTableGroup.vue";
export default {
  components: { ItemTableGroup },
  name: "AppTableGroup",
  data() {
    return {};
  },
  props: {
    type: {
      type: String,
      default() {
        return {};
      },
    },
    data_essence: {
      type: Array,
      default() {
        return {};
      },
    }


  },
  methods: {
    submitData() {
      this.$emit("checkCoach", this.checkedCoach);
    },
    addCoach() {
      this.$emit("addCoach");
    },
    editGroup(item) {
      this.$emit("editGroup", item);
    },
    deleteGroup(item) {
      console.log(item);
      // удаление групп
      this.$emit("deleteGroup", item, "GROUP");
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.enteredData.isLoading,
      coaches: (state) => state.enteredData.allCoaches,
    }),
  },
};
</script>

<style></style>
