<template>
  <tr>
    <td class="table__icon-btn">
      <span class="table__icon-edit" v-if="typeEdit !== 'mandate'"  @click="editSportsmen(item_sportsmen)">✏️</span>
      <span class="table__icon-delete"  @click="deleteSportsmen()">🗑️</span>
    </td>
<!--    <td class="edit__btn">-->
<!--      <img-->
<!--        class="icon__edit"-->
<!--        src="../../assets/svg/pain.svg"-->
<!--        alt="Edit"-->
<!--        @click="editSportsmen()"-->
<!--      />-->
<!--      <img-->
<!--        class="icon__delete"-->
<!--        src="../../assets/svg/trash.svg"-->
<!--        alt="Delete"-->
<!--        @click="deleteSportsmen()"-->
<!--      />-->
<!--    </td>-->

    <td class="">
      {{ item_sportsmen.surname }} {{ item_sportsmen.name }} {{item_sportsmen.patronymic}}
    </td>
    <td class="">
      {{ this.newData[0] }}
    </td>
    <td class="">
      {{ item_sportsmen.rank }}
    </td>
    <td  class="limited-text">
      {{ item_sportsmen.coachNames }}
    </td>
    <td  class="limited-text">
      {{ item_sportsmen.club }}
    </td>
  </tr>
</template>

<script>
export default {
  name: "AppItemTableSportsmen",
  data() {
    return {
      checkedP: false,
    };
  },
  props: {
    item_sportsmen: {
      type: Object,
      default() {
        return {};
      },
    },
    typeEdit: {
      type: Object,
    }
  },
  computed: {
    checkedCoach: {
      get() {
        return this.value;
      },
      set(val) {
        console.log(val, "val");
        this.checkedP = val;
      },
    },
    newData() {
      return this.item_sportsmen.dateOfBirth.split("-");
    },
  },
  methods: {
    onChange() {
      this.$emit("input", this.checkedP);
      this.$emit("updateCheck");
    },
    deleteSportsmen() {
      this.$emit("deleteSportsmen", this.item_sportsmen);
    },
    editSportsmen() {
      this.$emit("editSportsmen", this.item_sportsmen);
    },
  },
};
</script>

<style></style>
