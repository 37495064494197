<template>
  <div class="main-content">
    <Header message="Мандатная (Админ)" />
    <BreadCrumps/>
    <div class="mandated__container">
      <div class="mandated__statistics">
        <button
            class="mandated__tag statistics"
            @click="routingStat()"
        >
          Посмотреть статистику соревнований
        </button>
      </div>
      <div class="detail-competition__card">
        <div class="detail-competition__card-poster">
          <img
            v-if="allInfoMandate.urlImage !== null"
            class="preview-block__img"
            :src="`${allInfoMandate.urlImage}`"
            alt="foto"
          />
          <img v-else src="../../assets/img/poster.png" alt="poster" />
<!--          -->
        </div>
        <div class="detail-competition__card-info">
          <div class="detail-competition__cart-info-title">
            <p class="index-text">
              {{ allInfoMandate.title }}
            </p>
          </div>
          <div
            class="detail-competition__cart-info-title"
            v-if="allInfoMandate.isDraw"
          >
            <p class="text-succses">ЖЕРЕБЬЕВКА ПРОВЕДЕНА!</p>
          </div>

          <div class="detail-competition__cart-info-text">
            <p class="text-label">Место проведения:</p>
            <p class="text-description">
              {{ allInfoMandate.place }}
            </p>
          </div>
          <div class="detail-competition__cart-info-date">
            <div class="detail-competition__cart-info-date-from">
              <p class="text-label">Дата проведения:</p>
              <p class="text-description">
                {{ dateOfFromComp }}-{{ dateOfToComp }}
              </p>
            </div>
            <div class="detail-competition__cart-info-date-to">
              <p class="text-label">Приём заявок до:</p>

              <p class="text-description">{{ dateOfPrismApp }}</p>
            </div>
          </div>
          <div class=" detail-competition__cart-download competition__item-btn">
            <button class="competition-btn competition-btn--view" @click="downloadCompetition">
              <img
                  class="competition-btn__img"
                  src="../../assets/svg/download.svg"
                  alt="download"
              />
              Скачать всех участников
            </button>
            <button class="competition-btn competition-btn--menu" @click="downloadCompetitionZip">
              <img
                  class="competition-btn__img"
                  src="../../assets/svg/zip.svg"
                  alt="download"
              />
<!--              <span class="competition-btn__tooltip">Скачать архив</span>-->
            </button>

          </div>
          <div class="detail-competition__detail">
            <p class="text-label">Перед созданием стартового необходимо провести жеребьевку!</p>
          </div>
          <div class="detail-competition__cart-info-date">
          <div class="detail-competition__btn" v-if="!allInfoMandate.isDraw">

            <button class="mandated__tag active" @click="sortitionStartList()">
              Провести жеребьевку
            </button>
          </div>
          <div class="detail-competition__btn">
            <button
              :disabled="!allInfoMandate.isDraw"
              class="mandated__tag active"
              @click="createStartList()"
            >
              Создать стартовый
            </button>
          </div>

          </div>
        </div>
      </div>
      <AppSelectionBlocks  :activeBlock="activeBlock"
                           :sportsmansCount="allInfoMandate.sportsmansCount"
                           :groupsCount="allInfoMandate.groupsCount"
                           :duetsCount="allInfoMandate.duetsCount"
                           :triosCount="allInfoMandate.triosCount"
                           :judges="allInfoMandate.judges"
                           @block-click="setActiveBlock"/>

      <div
        class="mandated-info-juj"
        v-if="activeBlock === 'judges' && allInfoMandate"
      >
        <div class="mandated-info-juj__download">
          <div
            class="mandated__tag button-cancel"
            @click="downloadFileForJuj"
          >
            <div class="mandated-info-juj__download-text">
              <p class="mandated-info-juj__text">Скачать список судей</p>
            </div>

            <img src="../../assets/svg/download-file.svg" alt="" />
          </div>
        </div>
        <table-app-judge :allJudges="this.allInfoMandate.judges" />

        <!-- <empty-block v-else /> -->
      </div>
      <info-about-sportsmens
          v-if="activeBlock === 'sportsmens' && individualCategories"
        :listNominationInd="this.individualCategories"
      />
      <info-about-groups
          v-if="activeBlock === 'clubs' && groupCategories"
        :listNominationGroup="this.groupCategories"
      />
      <app-info-about-trio   v-if="activeBlock === 'trios' && trioCategories"   :listNominationTrio="this.trioCategories" />
      <app-info-about-duo   v-if="activeBlock === 'duets' && duoCategories"   :listNominationTrio="this.duoCategories"/>
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import { mapState } from "vuex";
import { actionTypesMandated } from "@/store/modules/mandated";
import { actionTypes } from "@/store/modules/registration";
// import EmptyBlock from "../../components/EmptyBlock.vue";
import TableAppJudge from "../../components/TableInModal/TableAppJudge.vue";
// import ItemTableJuj from "../../components/Table/ItemTableJuj.vue";
import axios from "axios";
import InfoAboutSportsmens from "./InfoAboutSportsmens.vue";
import InfoAboutGroups from "./InfoAboutGroups.vue";
import BreadCrumps from '@/components/BreadCrumps.vue'
import AppSelectionBlocks from '@/components/SelectionBlocks.vue'
import AppInfoAboutTrio from '@/views/Mandated/InfoAboutTrio.vue'
import AppInfoAboutDuo from '@/views/Mandated/InfoAboutDuo.vue'

export default {
  components: {
    AppInfoAboutDuo,
    AppInfoAboutTrio,
    AppSelectionBlocks,
    BreadCrumps,
    Header,
    TableAppJudge,
    InfoAboutSportsmens,
    InfoAboutGroups,
  },
  name: "AppItemMandatedInfo",
  data() {
    return {
      // isActiveBlock: false,
      // isActiveGroups: false,
      // isActiveCoachs: false,
      // isActiveJudjes: false,
      // isActiveSportsmens: true,
      activeBlock: 'sportsmens' // По умолчанию активный блок — sportsmens
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.competition.isLoading,
      allInfoMandate: (state) => state.mandated.allInfoMandate,
      groupCategories: (state) => state.registration.groupCategories,
      individualCategories: (state) => state.registration.individualCategories,
      trioCategories: (state) => state.registration.trioCategories,
      duoCategories: (state) => state.registration.duoCategories,
      allStatistics: (state) => state.statistics.allStatistics,
    }),
    dateOfFromComp() {
      return this.$moment(this.allInfoMandate.fromDate)
        .locale("ru")
        .format("LL");
    },
    dateOfToComp() {
      return this.$moment(this.allInfoMandate.toDate).locale("ru").format("LL");
    },
    dateOfPrismApp() {
      return this.$moment(this.allInfoMandate.applicationsBefore)
        .locale("ru")
        .format("LL");
    },
  },

  methods: {
    setActiveBlock(tab) {
      this.activeBlock = tab;
    },
    sortitionStartList() {
      this.$store
        .dispatch(actionTypesMandated.sortition, this.$route.params.id)
        .then(() => {})
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    downloadFileForJuj() {
      axios
        .get(`/get/mandate/judge/${this.$route.params.id}`, {
          responseType: "blob",
        })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          var fileName = `Список_Судей.xls`;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        });
    },
    downloadCompetition() {
      axios
          .get(`/get/mandate/performances/${this.$route.params.id}`, {
            responseType: "blob",
          })
          .then((response) => {
            const url = URL.createObjectURL(
                new Blob([response.data], {
                  type: "application/vnd.ms-excel",
                })
            );
            var fileName = `Список_участников.xls`;
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
          });
    },
    downloadCompetitionZip() {
      axios
          .get(`/get/mandate/archive-from/categories/competition/${this.$route.params.id}`, {
            responseType: "blob",
          })
          .then((response) => {
            const url = URL.createObjectURL(
                new Blob([response.data], {
                  type: "application/zip", // Устанавливаем MIME-тип для ZIP
                })
            );
            const fileName = `Список_участников.zip`; // Указываем имя файла с расширением .zip
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove(); // Удаляем ссылку после клика
            URL.revokeObjectURL(url); // Освобождаем память
          })
          .catch((error) => {
            console.error("Ошибка при скачивании архива:", error);
            alert("Не удалось скачать архив. Попробуйте позже.");
          });
    },
    createStartList() {
      this.$router.push({
        name: "CreateStartList",
        params: {
          id: this.$route.params.id,
          // title: this.itemCompetition.title,
        },
      });
    },
    routingStat() {
      this.$router.push({
        name: "MandatedStatistics",
        params: {
          id: this.$route.params.id,
        },
      });
    }
  },
  mounted() {
    this.$store
      .dispatch(actionTypesMandated.getAllInfoMandated, this.$route.params.id)
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });
    // Получение информации о номинациях
    this.$store
      .dispatch(actionTypes.getIndividualCategories, this.$route.params.id)
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });
    //
    this.$store
      .dispatch(actionTypes.getGroupCategories, this.$route.params.id)
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });

  },
};
</script>

