<template>
  <div class="main">
    <div class="loader-overlay" v-if="isLoading || isLoadingNotAuth ">
      <div class="loader"></div>
    </div>
    <div  :class="{ 'main__container': token === null }">
      <div class="main__filter">
        <div class="filter">
<!--          <div-->
<!--            class="filter__block2"-->
<!--            :class="{ filter__block: this.isActiveBlockStartComp }"-->
<!--            @click="switchTabsClass('start')"-->
<!--          >-->
<!--            <p-->
<!--              class="filter__text2"-->
<!--              :class="{ activeText: this.isActiveBlockStartComp }"-->
<!--            >-->
<!--              Актуальные-->
<!--            </p>-->
<!--          </div>-->
<!--          <div-->
<!--            class="filter__block2"-->
<!--            :class="{ filter__block: this.isActiveBlockCloseComp }"-->
<!--            @click="switchTabsClass('close')"-->
<!--          >-->
<!--            <p-->
<!--              class="filter__text2"-->
<!--              :class="{ activeText: this.isActiveBlockCloseComp }"-->
<!--            >-->
<!--              Завершенные-->
<!--            </p>-->
<!--          </div>-->
        </div>
<!--        <div class="search" style="flex-direction: row !important">-->
<!--          <div class="search__input">-->
<!--            <img-->
<!--              src="../assets/svg/Search.svg"-->
<!--              alt=""-->
<!--              class="inputs-search__img"-->
<!--            />-->
<!--            <input-->
<!--              type="text"-->
<!--              class="inputs-search"-->
<!--              placeholder="Поиск по названию соревнований "-->
<!--              @input="searchCompetitions"-->
<!--            />-->
<!--          </div>-->
<!--&lt;!&ndash;          <div class="search__calendar">&ndash;&gt;-->
<!--&lt;!&ndash;            <input&ndash;&gt;-->
<!--&lt;!&ndash;              type="date"&ndash;&gt;-->
<!--&lt;!&ndash;              class="inputs-date just-gray"&ndash;&gt;-->
<!--&lt;!&ndash;              placeholder="Время, место"&ndash;&gt;-->
<!--&lt;!&ndash;            />&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </div>-->
      </div>
      <div class="competition">
        <item-verification-competition
          v-for="itemVerComp in this.filteredCompetitions"
          :key="itemVerComp.id"
          v-bind:item_ver_comp="itemVerComp"
          :isMandated="isMandated"
        />
      </div>
      <div
          class="new-competition__wrap"
          v-if="(!this.token && (this.allCompetitionsNotAuth === null || !this.filteredCompetitions.length)) || (this.token && (this.allCompetition === null || !this.filteredCompetitions.length))"
      >
        <p class="new-competition__title">Нет предстоящих соревнований!</p>
        <img
          src="../assets/img/empty_low.png"
          alt="application"
          class="new-competition__img"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { actionTypes } from "@/store/modules/competition";
import { mapState } from "vuex";
import ItemVerificationCompetition from "./ItemVerificationCompetition.vue";
import { actionTypesWithoutAuth } from '@/store/modules/withoutAuthorization'
export default {
  components: { ItemVerificationCompetition },
  name: "AppCompetitionBlocks",
  data() {
    return {
      isActiveBlockStartComp: true,
      isActiveBlockCloseComp: false,
      isMandated: false,
      filteredCompetitions:'',
    };
  },
  methods: {
    switchTabsClass(tab) {
      if (tab === "start") {
        this.isActiveBlockStartComp = true;
        this.isActiveBlockCloseComp = false;
      } else if (tab === "close") {
        this.isActiveBlockStartComp = false;
        this.isActiveBlockCloseComp = true;
      }
    },
    searchCompetitions(event) {
      const query = event.target.value.toLowerCase();
      const competitions = this.token ? this.allCompetition : this.allCompetitionsNotAuth;
      this.filteredCompetitions = query
          ? competitions.filter(competition => competition.title.toLowerCase().includes(query))
          : competitions;
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.competition.isLoading,
      isLoadingNotAuth: (state) => state.withoutAuth.isLoading,
      allCompetition: (state) => state.competition.allCompetition,
      allCompetitionsNotAuth:(state) => state.withoutAuth.allCompetitionsNotAuth,
      token: (state) => state.auth.token,
    }),
  },
  mounted() {
    if(this.token !== '') {
      this.$store
          .dispatch(actionTypes.allCompetition)
          .then(() => {
            // this.filteredCompetitions.map((item) => {
            //   if (item.isEndRegistration = true) {
            //     this.allActiveApplication.push(item);
            //   } else if (item.status ==="END") {
            //     this.allFinishApplication.push(item);
            //   } else {
            //     this.allDraftApplication.push(item);
            //   }
            // });
            this.searchCompetitions({ target: { value: '' } });
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
    } else {
      this.$store
          .dispatch(actionTypesWithoutAuth.allCompetitions)
          .then(() => {
            this.searchCompetitions({ target: { value: '' } });
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
    }

  },
};
</script>

<style></style>
