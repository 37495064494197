var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"new-login-page"},[_c('header',{staticClass:"new-login-page__header"},[_c('router-link',{attrs:{"to":"/"}},[_c('div',{staticClass:"header__logo"},[_c('img',{staticClass:"header__logo-img",attrs:{"src":require("../assets/img/LogoRG-hor.png"),"alt":"logo"}})])]),_c('div',{staticClass:"header__btn"},[_c('router-link',{attrs:{"to":"/"}},[_c('button',{staticClass:"login-btn background-green color-white"},[_vm._v("На главную")])])],1)],1),_c('div',{},[_c('div',{staticClass:"new-login-page__main"},[(_vm.isLoading)?_c('div',{staticClass:"loader-overlay"},[_c('div',{staticClass:"loader"})]):_vm._e(),_c('div',{staticClass:"new-login-page__100-block"},[_vm._m(0),(_vm.isSubmitted && this.$v.$invalid)?_c('div',{staticClass:"message-about-empty empty"},[_c('p',{staticClass:"message-about-empty__text"},[_vm._v("Заполните все данные!")])]):_vm._e(),(_vm.messageEmail)?_c('div',{staticClass:"message-about-empty confirmed"},[_c('p',{staticClass:"message-about-empty__text"},[_vm._v("Спасибо за регистрацию! "),_c('router-link',{attrs:{"to":"/login"}},[_c('span',{staticClass:"description-bold-white"},[_vm._v("Войти")])]),_vm._v(" Для получения отчетов и уведомлений подтвердите почту.")],1)]):_vm._e(),_c('form',{staticClass:"submit-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[(_vm.validationErrorsRegistration)?_c('ValidationErrors',{attrs:{"validation-errors":_vm.validationErrorsRegistration.message}}):_vm._e(),_c('fieldset',{staticClass:"submit-registration__input-wrap"},[_c('div',{staticClass:"input-block"},[_c('p',{staticClass:"label-text",attrs:{"for":"email"}},[_vm._v("Почта")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"input-light-text",class:{
                    'errorInput': _vm.isSubmitted && _vm.$v.email.$error,
                  },attrs:{"type":"text","placeholder":"Введите адрес почты","name":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),_c('div',{staticClass:"input-block"},[_c('p',{staticClass:"label-text",attrs:{"for":"name"}},[_vm._v("Имя")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"input-light-text",class:{
                    'errorInput': _vm.isSubmitted && _vm.$v.name.$error,
                  },attrs:{"type":"text","placeholder":"Введите имя","name":"name"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}})])]),_c('fieldset',{staticClass:"submit-registration__input-wrap"},[_c('div',{staticClass:"input-block"},[_c('p',{staticClass:"label-text",attrs:{"for":"surname"}},[_vm._v("Фамилия")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.surname),expression:"surname"}],staticClass:"input-light-text",class:{
                    'errorInput': _vm.isSubmitted && _vm.$v.surname.$error,
                  },attrs:{"type":"text","placeholder":"Введите фамилию","name":"surname"},domProps:{"value":(_vm.surname)},on:{"input":function($event){if($event.target.composing)return;_vm.surname=$event.target.value}}})]),_c('div',{staticClass:"input-block"},[_c('p',{staticClass:"label-text",attrs:{"for":"middleName"}},[_vm._v("Отчество")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.middleName),expression:"middleName"}],staticClass:"input-light-text",class:{
                    'errorInput': _vm.isSubmitted && _vm.$v.middleName.$error,
                  },attrs:{"type":"text","placeholder":"Введите отчество","name":"middleName"},domProps:{"value":(_vm.middleName)},on:{"input":function($event){if($event.target.composing)return;_vm.middleName=$event.target.value}}})])]),(!_vm.checkRepeatPassword &&  _vm.isSubmitted)?_c('p',{staticClass:"validation__text-red"},[_vm._v(_vm._s(_vm.validationError))]):_vm._e(),_c('fieldset',{staticClass:"submit-registration__input-wrap"},[_c('div',{staticClass:"input-block"},[_c('p',{staticClass:"label-text",attrs:{"for":"password"}},[_vm._v("Пароль")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"input-light-text",class:{
                    'errorInput': (_vm.isSubmitted && _vm.$v.password.$error) || (_vm.isSubmitted &&!_vm.checkRepeatPassword),
                  },attrs:{"type":"password","placeholder":"Введите пароль","name":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})]),_c('div',{staticClass:"input-block"},[_c('p',{staticClass:"label-text",attrs:{"for":"repeatPassword"}},[_vm._v("Повторите пароль")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.repeatPassword),expression:"repeatPassword"}],staticClass:"input-light-text",class:{
                    'errorInput': (_vm.isSubmitted && _vm.$v.repeatPassword.$error) || (_vm.isSubmitted &&!_vm.checkRepeatPassword),
                  },attrs:{"type":"password","placeholder":"Повторите пароль","name":"repeatPassword"},domProps:{"value":(_vm.repeatPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.repeatPassword=$event.target.value}}})])]),_c('fieldset',{staticClass:"submit-registration__input-wrap"},[_c('div',{staticClass:"input-block"},[_c('p',{staticClass:"label-text",attrs:{"for":"region"}},[_vm._v("Выбрать Область/регион:")]),_c('AppRegionSelect',{class:{
                    'errorInput': _vm.isSubmitted && _vm.$v.region.$error,
                  },on:{"regionSelected":_vm.safeRegion}})],1),(this.region)?_c('div',{staticClass:"input-block"},[_c('p',{staticClass:"label-text",attrs:{"for":"city"}},[_vm._v("Выбрать город")]),_c('AppCitySelect',{class:{
                    'errorInput': _vm.isSubmitted && _vm.$v.city.$error,
                  },attrs:{"idRegion":this.region.id},on:{"citySelected":_vm.safeCity}})],1):_vm._e()]),_c('fieldset',{staticClass:"submit-registration__input-wrap"},[_c('div',{staticClass:"input-block-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkedCoach),expression:"checkedCoach"}],staticClass:"select__person",attrs:{"type":"checkbox","name":"coach"},domProps:{"checked":Array.isArray(_vm.checkedCoach)?_vm._i(_vm.checkedCoach,null)>-1:(_vm.checkedCoach)},on:{"change":function($event){var $$a=_vm.checkedCoach,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkedCoach=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkedCoach=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkedCoach=$$c}}}}),_c('label',{staticClass:"label-text margin-left15",attrs:{"for":"coach"}},[_vm._v("Я тренер")])]),(_vm.checkedCoach)?_c('div',{staticClass:"input-block"},[_c('p',{staticClass:"label-text",attrs:{"for":"club"}},[_vm._v("Название школы/клуба")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.club),expression:"club"}],staticClass:"input-light-text",class:{ errorInput: _vm.isSubmitted && _vm.$v.club.$error },attrs:{"type":"text","placeholder":"Введите название школы/клуба","name":"club"},domProps:{"value":(_vm.club)},on:{"input":function($event){if($event.target.composing)return;_vm.club=$event.target.value}}})]):_vm._e()]),_vm._m(1),_c('div',{staticClass:"new-login-page__description-reg"},[_c('p',{staticClass:"description"},[_vm._v(" У Вас есть аккаунт? "),_c('router-link',{attrs:{"to":"/login"}},[_c('span',{staticClass:"description-bold"},[_vm._v("Войти")])])],1)])],1)])])]),_c('ModalRegistration',{attrs:{"isVisible":_vm.isModalVisible},on:{"close":function($event){_vm.isModalVisible = false}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"new-login-page__title-reg"},[_c('p',{staticClass:"title-in-page"},[_vm._v("Регистрация")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"submit-login__button"},[_c('button',{staticClass:"button"},[_vm._v("Зарегистрироваться")])])
}]

export { render, staticRenderFns }