<template>
  <div class="new-login-page">
    <header class="new-login-page__header">
      <router-link to="/">
        <div class="header__logo">
          <img src="../assets/img/LogoRG-hor.png" class="header__logo-img" alt="logo">
        </div>
      </router-link>
      <div class="header__btn">
        <router-link to="/">
          <button class="login-btn background-green color-white ">На главную</button>
        </router-link>
      </div>
    </header>
    <div class="">

      <div class="new-login-page__main">
        <div class="loader-overlay" v-if="isLoading">
          <div class="loader"></div>
        </div>
        <div class="new-login-page__100-block">
          <div class="new-login-page__title-reg">
            <p class="title-in-page">Регистрация</p>
          </div>
          <div
              class="message-about-empty empty"
              v-if="isSubmitted && this.$v.$invalid"
          >
            <p class="message-about-empty__text">Заполните все данные!</p>
          </div>
          <div class="message-about-empty confirmed" v-if="messageEmail">
            <p class="message-about-empty__text">Спасибо за регистрацию! <router-link to="/login"><span class="description-bold-white">Войти</span> </router-link>
              Для получения отчетов и уведомлений подтвердите почту.</p>
          </div>
          <form @submit.prevent="onSubmit" class="submit-form">
            <ValidationErrors
                v-if="validationErrorsRegistration"
                :validation-errors="validationErrorsRegistration.message"
            />
            <fieldset class="submit-registration__input-wrap">
              <div class="input-block">
              <p class="label-text" for="email">Почта</p>
              <input
                  type="text"
                  class="input-light-text"
                  placeholder="Введите адрес почты"
                  :class="{
                      'errorInput': isSubmitted && $v.email.$error,
                    }"
                  name="email"
                  v-model="email"
              />
              </div>
              <div class="input-block">
                <p class="label-text" for="name">Имя</p>
                <input
                    type="text"
                    class="input-light-text"
                    placeholder="Введите имя"
                    :class="{
                      'errorInput': isSubmitted && $v.name.$error,
                    }"
                    name="name"
                    v-model="name"
                />
              </div>
            </fieldset>
            <fieldset class="submit-registration__input-wrap">
              <div class="input-block">
                <p class="label-text" for="surname">Фамилия</p>
                <input
                    type="text"
                    class="input-light-text"
                    placeholder="Введите фамилию"
                    name="surname"
                    :class="{
                      'errorInput': isSubmitted && $v.surname.$error,
                    }"
                    v-model="surname"
                />
              </div>
              <div class="input-block">
                <p class="label-text" for="middleName">Отчество</p>
                <input
                    type="text"
                    class="input-light-text"
                    placeholder="Введите отчество"
                    name="middleName"
                    :class="{
                      'errorInput': isSubmitted && $v.middleName.$error,
                    }"
                    v-model="middleName"
                />
              </div>
            </fieldset>
            <p v-if="!checkRepeatPassword &&  isSubmitted" class="validation__text-red">{{ validationError }}</p>
            <fieldset class="submit-registration__input-wrap">
              <div class="input-block">
                <p class="label-text" for="password">Пароль</p>
                <input
                    type="password"
                    class="input-light-text"
                    placeholder="Введите пароль"
                    name="password"
                    :class="{
                      'errorInput': (isSubmitted && $v.password.$error) || (isSubmitted &&!checkRepeatPassword),
                    }"

                    v-model="password"
                />
              </div>
              <div class="input-block">
                <p class="label-text" for="repeatPassword">Повторите пароль</p>
                <input
                    type="password"
                    class="input-light-text"
                    :class="{
                      'errorInput': (isSubmitted && $v.repeatPassword.$error) || (isSubmitted &&!checkRepeatPassword),
                    }"
                    placeholder="Повторите пароль"
                    name="repeatPassword"
                    v-model="repeatPassword"
                />
              </div>
            </fieldset>
            <fieldset class="submit-registration__input-wrap">

              <div class="input-block">
                <p class="label-text" for="region">Выбрать Область/регион:</p>
                <AppRegionSelect  @regionSelected="safeRegion"    :class="{
                      'errorInput': isSubmitted && $v.region.$error,
                    }" />
              </div>
              <div class="input-block" v-if="this.region">
                <p class="label-text" for="city">Выбрать город</p>
                <AppCitySelect @citySelected="safeCity" :idRegion="this.region.id"  :class="{
                      'errorInput': isSubmitted && $v.city.$error,
                    }" />
              </div>
            </fieldset>
            <fieldset class="submit-registration__input-wrap">
              <div class="input-block-check">
                <input
                    class="select__person"
                    type="checkbox"
                    name="coach"
                    v-model="checkedCoach"
                />
                <label class="label-text margin-left15" for="coach">Я тренер</label>
              </div>

              <div class="input-block" v-if="checkedCoach">
                <p class="label-text" for="club">Название школы/клуба</p>
                <input
                    type="text"
                    class="input-light-text"
                    placeholder="Введите название школы/клуба"
                    name="club"
                    v-model="club"
                    :class="{ errorInput: isSubmitted && $v.club.$error }"
                />
              </div>
            </fieldset>
            <div class="submit-login__button">
              <button class="button">Зарегистрироваться</button>
            </div>
            <div class="new-login-page__description-reg">
              <p class="description"> У Вас есть аккаунт? <router-link to="/login"><span class="description-bold">Войти</span> </router-link></p></div>
          </form>
        </div>
      </div>
    </div>
    <ModalRegistration  :isVisible="isModalVisible"
                        @close="isModalVisible = false"/>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  // import { actionTypes } from '@/store/modules/auth'
  import ValidationErrors from '@/components/ValidationErrors.vue'
  import { required, requiredIf } from 'vuelidate/lib/validators'
  import { actionTypes } from '@/store/modules/auth'
  import AppRegionSelect from '@/components/customSelect/RegionSelect.vue'
  import AppCitySelect from '@/components/customSelect/CitySelect.vue'
  import ModalRegistration from '@/components/Modal/ModalRegistration.vue'


  export default {
    name: "AppRegistaration",
    components: { ModalRegistration, AppCitySelect, AppRegionSelect,  ValidationErrors },
    data() {
      return {
        email: "",
        name: "",
        middleName: "",
        surname: "",
        password: "",
        repeatPassword: '',
        club: "",
        checkedCoach: false,
        isSubmitted: false,
        messageEmail: false,
        city: '',
        region: '',
        defaultRegion: "Выберите регион",
        defaultCity: "Выберите город",
        validationError:  'Данные пароли не совпадают!',
        isModalVisible: false,
      };
    },
    validations: {
      email: {
        required,
      },
      name: {
        required,
      },
      middleName: {
        required,
      },
      surname: {
        required,
      },
      password: {
        required,
      },
      repeatPassword: {
        required,
      },
      city: {
        required,
      },
      region: {
        required,
      },
      club: {
        required: requiredIf(function () {
          return this.checkedCoach; // Валидация required только если checkedCoach === true
        }),
      },
    },
    computed: {
      ...mapState({
        validationErrorsRegistration: (state) => state.auth.validationErrorsRegistration,
        isLoading: (state) => state.auth.isLoading,
      }),
      checkRepeatPassword: function () {
        if (this.password !== this.repeatPassword) {
          return false;
        } else if (this.repeatPassword === "") {
          return false;
        } else {
          return true;
        }
      },
    },
    methods: {
      safeCity(item) {
        this.city = item;
      },
      safeRegion(item) {
        this.region = item;
      },
      onSubmit() {
        this.isSubmitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          console.log("Данные не заполнены или заполнены неверно");
          return;
        }
        // Ваш код отправки данных
        this.$store
            .dispatch(actionTypes.registration, {
              email: this.email,
              name: this.name,
              middleName: this.middleName,
              surname: this.surname,
              password: this.password,
              trainer: this.checkedCoach,
              ...(this.checkedCoach ? { club: this.club } : {}),
              region: this.region.name,
              city: this.city,
            })
            .then(() => {
              if (this.validationErrorsRegistration === null) {
                this.resetForm();
              }
              this.isModalVisible = true;
            })
            .catch((er) => {
              this.isSubmitted = false;
              console.log("Ошибка", er);
            });
      },
      resetForm() {
        this.isSubmitted = false;
        this.email = "";
        this.name = "";
        this.middleName = "";
        this.surname = "";
        this.password = "";
        this.repeatPassword = "";
        this.checkedCoach = false;
        this.club = "";
        this.city = "";
        this.region = "";
      },
    },
  };
</script>
