<template>
  <div class="header">
    <div class="header__right">

      <div class="logo-header" v-if="infoUser === null">
<!--        <router-link to="/">-->
<!--        <img src="../assets/svg/LogoRG-hor.svg" alt="logo" />-->
<!--        </router-link>-->
      </div>

      <div class="title-page">
        <p class="title-page__text">{{ message }}</p>
      </div>
    </div>
    <div class="header__left-anonymous" v-if="infoUser === null">
      <div class="not-autorization">
        <div class="not-autorization__button">
            <button class="button-border-light"  @click="login">Вход</button>
        </div>
        <div class="not-autorization__button">
            <button class="button" @click="registration">Регистрация</button>
        </div>
      </div>
      <div class="not-autorization-mini">
        <div class="login-btn background-green color-white"  @click="login">
          <p class="not-autorization-mini__text">Вход</p>
        </div>

      </div>
    </div>
    <div class="header__left" v-else>
<!--      <router-link to="/profile">-->
        <div class="header__mini">
          <div class="header__mini-profile">
            <img src="../assets/svg/User.svg" alt="profile" />
          </div>
          <p
            class="header__text-info"
            v-if="infoUser.name === null && infoUser.surname === null"
          >
            {{ infoUser.email }}
          </p>
          <p class="header__text-infо" v-else>
            {{ infoUser.surname }} {{ infoUser.name }}
          </p>
        </div>
<!--      </router-link>-->
      <div class="header__exit" @click="exit">
        <img src="../assets/svg/Exit.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { actionTypes } from "@/store/modules/auth";
import { mapState } from "vuex";
export default {
  name: "AppHeader",
  props: {
    message: String,
  },
  computed: {
    ...mapState({
      infoUser: (state) => state.auth.infoUser,
    }),
  },
  methods: {
    exit() {
      this.$store
        .dispatch(actionTypes.exitLogin)
        .then(() => {})
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    login() {
      this.$router.push({
        name: "Login",
      });
    },
    registration() {
      this.$router.push({
        name: "Registration",
      });
    }
  },
};
</script>

<style></style>
