<template>
  <div class="main-content">
    <div class="loader-overlay" v-if="isLoading">
      <div class="loader" >
        <LottieAnimation
            ref="anim"
            :animationData="require('@/assets/lottie/loader.json')"
            :autoPlay="true"
            :loop="true"
            :speed="1"
        />
      </div>
    </div>
    <Header message="Мои данные" />
    <div class="entered-data__container">
      <AppMiniRouting/>

      <!-- Спортсмены -->
      <div class="entered-data__title-with-svg" ref="sportsmenBlock">
        <p class="entered-data__title">Спортсмены</p>
        <div class="entered-data__wrap-svg" @click="openModal('showAddSportsmen')">
          <img src="../assets/svg/plus.svg" alt="plus" />
        </div>
      </div>
      <div class="entered-data__block-wrap" >
        <TableSportsmen
            v-if="allSportsmens && allSportsmens.length"
            @editSportsmen="editItem('item_sportsmen', 'isShowModifSportsmen', $event)"
            @deleteSportsmen="deleteItem($event, 'SPORTSMAN')"
        />
        <empty-block v-else />
      </div>

      <!-- Группы -->
      <div class="entered-data__title-with-svg" ref="groupBlock">
        <p class="entered-data__title">Группы</p>
        <div class="entered-data__wrap-svg" @click="openModal('showAddGroup')">
          <img src="../assets/svg/plus.svg" alt="plus" />
        </div>
      </div>
      <div class="entered-data__block-wrap" >
        <TableGroup
            v-if="allGroups && allGroups.length"
            type="GROUP"
            :data_essence="allGroups"
            @editGroup="editItem('item_group', 'isShowModiGroup', $event)"
            @deleteGroup="deleteItem($event, 'GROUP')"
        />
        <empty-block v-else />
      </div>

      <!-- Трио и Дуэты -->
<!--      <div class="entered-data__row" >-->
        <div class="entered-data__column" ref="trioBlock">
          <div class="entered-data__title-with-svg">
            <p class="entered-data__title">Трио</p>
            <div class="entered-data__wrap-svg" @click="openModal('showAddTrio')">
              <img src="../assets/svg/plus.svg" alt="plus" />
            </div>
          </div>
          <div class="entered-data__block-wrap" >
            <TableGroup
                v-if="allTrios && allTrios.length"
                type="TRIO"
                :data_essence="allTrios"
                @editGroup="editItem('item_trio', 'isShowModifTrio', $event)"
                @deleteGroup="deleteItem($event, 'TRIO')"
            />
            <empty-block v-else />
          </div>
        </div>
        <div class="entered-data__column" ref="duoBlock">
          <div class="entered-data__title-with-svg">
            <p class="entered-data__title">Дуэты</p>
            <div class="entered-data__wrap-svg" @click="openModal('showAddDuo')">
              <img src="../assets/svg/plus.svg" alt="plus" />
            </div>
          </div>
          <div class="entered-data__block-wrap" >
            <TableGroup
                v-if="allDuos && allDuos.length"
                type="DUO"
                :data_essence="allDuos"
                @editGroup="editItem('item_duo', 'isShowModifDuo', $event)"
                @deleteGroup="deleteItem($event, 'DUO')"
            />
            <empty-block v-else />
          </div>
        </div>
<!--      </div>-->

      <!-- Тренеры и Судьи -->
      <div class="entered-data__row">
        <div class="entered-data__column"  ref="coachBlock">
          <div class="entered-data__title-with-svg">
            <p class="entered-data__title">Тренеры</p>
            <div class="entered-data__wrap-svg" @click="openModal('showAddCoach')">
              <img src="../assets/svg/plus.svg" alt="plus" />
            </div>
          </div>
          <div class="entered-data__block-wrap">
            <TableCoach
                v-if="coaches && coaches.length"
                @editCoach="editItem('item_coach', 'isShowModifCoach', $event)"
                @deleteCoach="deleteItem($event, 'COACH')"
            />
            <empty-block v-else />
          </div>
        </div>
        <div class="entered-data__column"  ref="jujBlock">
          <div class="entered-data__title-with-svg">
            <p class="entered-data__title">Судьи</p>
            <div class="entered-data__wrap-svg" @click="openModal('showAddJuj')">
              <img src="../assets/svg/plus.svg" alt="plus" />
            </div>
          </div>
          <div class="entered-data__block-wrap" >
            <TableJuj
                v-if="judges && judges.length"
                @editJuj="editItem('item_juj', 'isShowModifJuj', $event)"
                @deleteJuj="deleteItem($event, 'JUDGE')"
            />
            <empty-block v-else />
          </div>
        </div>
      </div>
    </div>

    <!-- Модальные окна -->
    <modal-add-sportsmen
        v-if="modals.showAddSportsmen"
        @addCoach="openModal('showAddCoach')"
        @close="closeModal('showAddSportsmen')"
    />
    <modal-modification-sportsmen
        v-if="modals.isShowModifSportsmen"
        @addCoach="openModal('showAddCoach')"
        @close="closeModal('isShowModifSportsmen')"
        :item_sportsmen="item_sportsmen"
    />
    <modal-add-group
        v-if="modals.showAddGroup"
        @addCoach="openModal('showAddCoach')"
        @addSportsmen="openModal('showAddSportsmen')"
        @editSportsmen="editItem('item_sportsmen', 'isShowModifSportsmen', $event)"
        @close="closeModal('showAddGroup')"
    />
    <modal-modification-group
        v-if="modals.isShowModiGroup"
        @addCoach="openModal('showAddCoach')"
        @addSportsmen="openModal('showAddSportsmen')"
        @editSportsmen="editItem('item_sportsmen', 'isShowModifSportsmen', $event)"
        @close="closeModal('isShowModiGroup')"
        :item_group="item_group"
    />
    <modal-add-trio
        v-if="modals.showAddTrio"
        @addCoach="openModal('showAddCoach')"
        @addSportsmen="openModal('showAddSportsmen')"
        @editSportsmen="editItem('item_sportsmen', 'isShowModifSportsmen', $event)"
        @close="closeModal('showAddTrio')"
    />
    <modal-modification-trio
        v-if="modals.isShowModifTrio"
        @addCoach="openModal('showAddCoach')"
        @addSportsmen="openModal('showAddSportsmen')"
        @editSportsmen="editItem('item_sportsmen', 'isShowModifSportsmen', $event)"
        @close="closeModal('isShowModifTrio')"
        :item_trio="item_trio"
    />
    <modal-add-duo
        v-if="modals.showAddDuo"
        @addCoach="openModal('showAddCoach')"
        @addSportsmen="openModal('showAddSportsmen')"
        @editSportsmen="editItem('item_sportsmen', 'isShowModifSportsmen', $event)"
        @close="closeModal('showAddDuo')"
    />
    <modal-modification-duo
        v-if="modals.isShowModifDuo"
        @addCoach="openModal('showAddCoach')"
        @addSportsmen="openModal('showAddSportsmen')"
        @editSportsmen="editItem('item_sportsmen', 'isShowModifSportsmen', $event)"
        @close="closeModal('isShowModifDuo')"
        :item_duo="item_duo"
    />
    <modal-add-coach
        v-if="modals.showAddCoach"
        @close="closeModal('showAddCoach')"
    />
    <modal-modification-coach
        v-if="modals.isShowModifCoach"
        @addCoach="openModal('showAddCoach')"
        @close="closeModal('isShowModifCoach')"
        :item_coach="item_coach"
    />
    <modal-add-juj
        v-if="modals.showAddJuj"
        @close="closeModal('showAddJuj')"
    />
    <modal-modification-juj
        v-if="modals.isShowModifJuj"
        @close="closeModal('isShowModifJuj')"
        :item_juj="item_juj"
    />
    <modal-delete
        v-if="modals.isShowDelete"
        @close="closeModal('isShowDelete')"
        :item="item_delete"
        :role="role_to_delete"
    />
  </div>
</template>

<script>
  import Header from "../components/Header.vue";
  import ModalAddSportsmen from "../components/Modal/ModalAddSportsmen.vue";
  import TableSportsmen from "../components/Table/TableSportsmen.vue";
  import ModalModificationSportsmen from "../components/Modal/ModalModificationSportsmen.vue";
  import TableGroup from "../components/Table/TableGroup.vue";
  import ModalAddGroup from "../components/Modal/ModalAddGroup.vue";
  import ModalModificationGroup from "../components/Modal/ModalModificationGroup.vue";
  import TableCoach from "../components/Table/TableCoach.vue";
  import ModalAddCoach from "../components/Modal/ModalAddCoach.vue";
  import ModalModificationCoach from "../components/Modal/ModalModificationCoach.vue";
  import ModalAddTrio from "../components/Modal/ModalAddTrio.vue"; // Модалка для Трио
  import ModalModificationTrio from "../components/Modal/ModalModificationTrio.vue"; // Модалка для изменения Трио
  import ModalAddDuo from "../components/Modal/ModalAddDuo.vue"; // Модалка для Дуэтов
  import ModalModificationDuo from "../components/Modal/ModalModificationDuo.vue"; // Модалка для изменения Дуэтов
  import ModalAddJuj from "../components/Modal/ModalAddJuj.vue";
  import TableJuj from "../components/Table/TableJuj.vue";
  import ModalModificationJuj from "../components/Modal/ModalModificationJuj.vue";
  import ModalDelete from "../components/Modal/ModalDelete.vue";
  import EmptyBlock from "../components/EmptyBlock.vue";
  import { actionTypesEntered } from "@/store/modules/enteredData";
  import AppMiniRouting from '@/components/MiniRouting.vue'
  import { mapState } from "vuex";
  import LottieAnimation from 'lottie-web-vue'

  export default {
    components: {
      LottieAnimation,
      Header,
      ModalAddSportsmen,
      TableSportsmen,
      ModalModificationSportsmen,
      TableGroup,
      ModalAddGroup,
      ModalModificationGroup,
      TableCoach,
      ModalAddCoach,
      ModalModificationCoach,
      ModalAddTrio,
      ModalModificationTrio,
      ModalAddDuo,
      ModalModificationDuo,
      ModalAddJuj,
      TableJuj,
      ModalModificationJuj,
      ModalDelete,
      EmptyBlock,
      AppMiniRouting,
    },
    data() {
      return {
        modals: {
          showAddSportsmen: false,
          isShowModifSportsmen: false,
          showAddGroup: false,
          isShowModiGroup: false,
          showAddCoach: false,
          isShowModifCoach: false,
          showAddJuj: false,
          isShowModifJuj: false,
          showAddTrio: false,
          isShowModifTrio: false,
          showAddDuo: false,
          isShowModifDuo: false,
          isShowDelete: false,
        },
        item_sportsmen: null,
        item_group: null,
        item_coach: null,
        item_juj: null,
        item_trio: null,
        item_duo: null,
        item_delete: null,
        role_to_delete: null,
      };
    },
    computed: {
      ...mapState({
        isLoading: (state) => state.enteredData.isLoading,
        //Спортсмены
        allSportsmens: (state) => state.enteredData.allSportsmens,
        //Группы
        allGroups: (state) => state.enteredData.allGroups,
        //Судьи
        judges: (state) => state.enteredData.allJuj,
        //Тренеры
        coaches: (state) => state.enteredData.allCoaches,
        //Трио
        allTrios: (state) => state.enteredData.allTrios,
        //Дуэты
        allDuos: (state) => state.enteredData.allDuos,
      }),
    },
    methods: {
      openModal(modalName) {
        this.modals[modalName] = true;
        document.body.classList.add('no-scroll');
      },
      closeModal(modalName) {
        // console.log(modalName, 'closeModal')
        this.modals[modalName] = false;
        // Прокрутка к нужному блоку после закрытия модального окна
        this.scrollToBlock(modalName);
        if (!this.anyModalOpen()) {
          document.body.classList.remove('no-scroll');
        }
      },
      anyModalOpen() {
        return Object.values(this.modals).some(isOpen => isOpen);
      },
      scrollToBlock(modalName) {
        this.$nextTick(() => {
          let blockRef = null;

          switch (modalName) {
            case 'showAddTrio':
            case 'isShowModifTrio':
              blockRef = this.$refs.trioBlock;
              break;
            case 'showAddGroup':
            case 'isShowModiGroup':
              blockRef = this.$refs.groupBlock;
              break;
            case 'showAddSportsmen':
            case 'isShowModifSportsmen':
              blockRef = this.$refs.sportsmenBlock;
              break;
            case 'showAddCoach':
            case 'isShowModifCoach':
              blockRef = this.$refs.coachBlock;
              break;
            case 'showAddDuo':
            case 'isShowModifDuo':
              blockRef = this.$refs.duoBlock;
              break;
            case 'showAddJuj':
            case 'isShowModifJuj':
              blockRef = this.$refs.jujBlock;
              break;
            default:
              break;
          }

          if (blockRef) {
            // console.log('Скролл', blockRef);

            setTimeout(() => {
              blockRef.scrollIntoView({
                behavior: 'smooth',  // Плавный скролл
                block: 'start',      // Скролл к началу блока
              });
            }, 100);  // Добавляем небольшую задержку
          }
        });
      },

      editItem(itemName, modalName, item) {
        this[itemName] = item;
        this.openModal(modalName);
      },
      deleteItem(item, role) {
        this.item_delete = item;
        this.role_to_delete = role;
        this.openModal('isShowDelete');
      },
    },
    mounted() {
      this.$store.dispatch(actionTypesEntered.getSportsmens).catch(console.error);
      this.$store.dispatch(actionTypesEntered.getGroups).catch(console.error);
      this.$store.dispatch(actionTypesEntered.getTrios).catch(console.error);
      this.$store.dispatch(actionTypesEntered.getDuos).catch(console.error);
      this.$store.dispatch(actionTypesEntered.getCoaches).catch(console.error);
      this.$store.dispatch(actionTypesEntered.getJuj).catch(console.error);
    },
  };
</script>

<style scoped>
/* Ваши стили */
</style>
